const hx = require('hyperdom/hyperx')
const idWithoutService = require('../idWithoutService')
const link = require('../partials/link')
const Modal = require('../components/Modal')
const renderLoading = require('../partials/loading')

module.exports = class ManageReposView {
  constructor({ server, loadUser }) {
    this.server = server
    this.loadUser = loadUser
    this.allRepos = []
    this.activeRepos = []
    this.search = ''
    this.startTrialModal = new Modal()
  }

  async onload() {
    this.loadingMessage = 'Loading repositories…'
    this.allRepos = await this.server.getUserRepos()
    this.activeRepos = await this.server.getUserReposActive()
    await this.loadingApp
    delete this.loadingMessage
  }

  async refreshRepos() {
    this.loadingMessage = 'Reloading repositories…'
    this.allRepos = await this.server.getUserRepos(true)
    delete this.loadingMessage
  }

  async startTrial(repoName) {
    const [service, userName] = repoName.split('/')
    await this.server.startTrial(`${service}/${userName}`)
    await this.loadUser()
    this.startTrialModal.close()
    delete this.repoSelectedForTrial
    await this.toggleRepo({
      repoName,
      openSource: false
    })
  }

  isActive(repoName) {
    return this.activeRepos.indexOf(repoName) !== -1
  }

  async toggleRepo(repo) {
    const repoName = repo.repoName
    if (this.isActive(repoName)) {
      this.activeRepos = await this.server.deleteUserReposActive(repoName)
    } else if (this.isProForRepo(repo)) {
      this.activeRepos = await this.server.updateUserReposActive(repoName)
    } else {
      this.startTrialModal.open()
      this.repoSelectedForTrial = repoName
    }
  }

  isProForRepo(repo) {
    const [service, userName] = repo.repoName.split('/')
    return (
      repo.openSource ||
      this.user.proOrgs.indexOf(`${service}/${userName}`) !== -1
    )
  }

  renderHeaderBar() {
    return hx`<div class="HeaderBar">
      <div class="Container">
        <div class="HeaderBar-inner">
          <h1 class="HeaderBar-heading">Manage Repositories</h1>
          <div class="HeaderBar-controls">
            <button onclick=${() =>
              this.refreshRepos()} class="Button Button--outlinePrimary Button--small">
              Reload Repositories
            </button>
          </div>
        </div>
      </div>
    </div>`
  }

  filteredRepos() {
    return this.allRepos.filter(repo => {
      const lowerRepo = repo.repoName.toLowerCase()
      const lowerSearch = this.search.toLowerCase()
      return lowerRepo.indexOf(lowerSearch) > -1 || this.search === ''
    })
  }

  renderRepoToggle(repo) {
    const view = this
    return hx`<li class="ToggleList-item">
      <label class=${{
        'ToggleList-toggleBar': true,
        'is-active': this.isActive(repo.repoName),
        'is-disabled': !this.isProForRepo(repo)
      }}>
        <div class="ToggleList-toggle">
          <input class="ToggleList-toggleInput" type="checkbox" binding=${{
            get() {
              return view.isActive(repo.repoName)
            },
            set() {
              return view.toggleRepo(repo)
            }
          }} />
          <div class="ToggleList-toggleSlider"></div>
        </div>
        <span class="ToggleList-toggleText">
          ${idWithoutService(repo.repoName)}
          ${
            repo.openSource
              ? undefined
              : hx`<span class="Label ToggleList-toggleLabel">Private</span>`
          }
        </span>
      </label>
      ${
        this.isActive(repo.repoName)
          ? hx`<div class="ToggleList-wrapButtons">
              ${link(`/${repo.repoName}`, 'View Project', {
                class:
                  'Button Button--outlinePrimary Button--small ToggleList-button'
              })}
              ${link(`/${repo.repoName}/reports/new`, 'New Report', {
                class:
                  'Button Button--outlinePrimary Button--small ToggleList-button'
              })}
            </div>`
          : undefined
      }
    </li>`
  }

  renderRepoList() {
    const filteredRepos = this.filteredRepos()
    if (filteredRepos.length > 0) {
      return hx`<ul class="ToggleList">
        ${filteredRepos.map(repo => this.renderRepoToggle(repo))}
      </ul>`
    }
    return hx`<p class="Alert Alert--danger">No repositories match.</p>`
  }

  render() {
    if (this.loadingMessage) {
      return renderLoading(this.loadingMessage)
    }
    return hx`<div>
      ${this.renderHeaderBar()}
      <div class="Container">
        <div class="PageHeader">
          <p class="PageHeader-subtext">
            Control which repositories are enabled as Shipping Report projects.
          </p>
        </div>
        ${
          this.alertMessage
            ? hx`<p class="Alert Alert--warning u-mb10">${
                this.alertMessage
              }</p>`
            : undefined
        }
        <div class="FilterSearch">
          <div class="FormField">
            <label class="u-hiddenVisually" for="filter-repos">Filter Repositories</label>
            <input placeholder="Filter repositories…" class="FormField-input" binding=${[
              this,
              'search'
            ]} type="search" id="filter-repos" />
          </div>
        </div>
        ${this.renderRepoList()}
        ${this.startTrialModal.renderContent({
          onCancel: () => {
            delete this.repoSelectedForTrial
          },
          content: hx`<div class="u-textCenter">
            <h3 class="u-mb10">Pro Account Required</h3>
            <p class="u-mb20">Private repos can only be used with a Pro Account.</p>
            <button type="button" class="Button Button--primary Button--wide" onclick=${() =>
              this.startTrial(this.repoSelectedForTrial)}>Start Trial</button>
          </div>`
        })}
      </div>
    </div>`
  }
}
