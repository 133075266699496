const DatePicker = require('./components/DatePicker')
const formatDate = require('date-fns/format')
const GitHub = require('./providers/github')
const h = require('hyperdom').html
const hx = require('hyperdom/hyperx')
const hyperdom = require('hyperdom')
const renderIcon = require('./partials/icon')
const renderLoading = require('./partials/loading')
const renderMarkdown = require('./renderMarkdown')
const renderTags = require('./partials/tags')

module.exports = class Report {
  constructor(options, server) {
    this.options = options
    this.server = server
    this.repo = options.repo
    this.reportId = options.reportId
    this.internalId = options.internalId
    this.allPullRequests = options.pullRequests
    this.startDate = options.startDate
    this.endDate = options.endDate
    this.excludedPRs = options.excludedPRs || []
    this.reportViewCount = options.reportViewCount
    this.summary = options.summary

    this.datePicker = new DatePicker({
      defaultDate: [this.startDate, this.endDate],
      onchange: (startDate, endDate, picker) => {
        this.startDate = startDate
        this.endDate = endDate
        picker.setDate([this.startDate, this.endDate])
      },

      render: () => {
        return hx`change`
      }
    })
  }

  get pullRequests() {
    return this.allPullRequests
      .filter(
        p =>
          new Date(p.merged_at) > this.startDate &&
          new Date(p.merged_at) < this.endDate
      )
      .sort(
        (a, b) =>
          new Date(a.merged_at).getTime() - new Date(b.merged_at).getTime()
      )
  }

  handleSaveClick() {
    return this.save()
  }

  get reportPullRequests() {
    return this.pullRequests.filter(p => {
      return this.excludedPRs.indexOf(p.id) === -1
    })
  }

  async save() {
    const startDate = this.startDate
    const endDate = this.endDate
    const report = {
      reportId: this.reportId,
      internalId: this.internalId,
      startDate,
      endDate,
      repo: this.repo,
      summary: this.summary,
      pullRequests: this.reportPullRequests,
      excludedPRs: this.excludedPRs
    }
    this.savingReport = true
    this.refresh()
    this.reportId = await this.server.saveReport(report)
    window.history.pushState(
      {
        savedReportId: this.reportId
      },
      null,
      `/${this.repo}`
    )
    delete this.savingReport
    this.refresh()
  }

  showHidePR(pr) {
    if (this.excludedPRs.indexOf(pr.id) === -1) {
      this.excludedPRs.push(pr.id)
    } else {
      this.excludedPRs = this.excludedPRs.filter(prId => prId !== pr.id)
    }
    return false
  }

  renderChangesList() {
    if (this.pullRequests.length > 0) {
      return this.pullRequests.map(pr => this.renderPullRequest(pr))
    }
    return hx`<p class="Alert Alert--warning">No merged changes in this period.</p>`
  }

  renderPullRequest(pr) {
    const prHidden = this.excludedPRs.indexOf(pr.id) > -1
    if (prHidden) {
      return hx`<p class="Alert Alert--info Alert--small">
        "${
          pr.title
        }" has been hidden from the report. <a href="#" onclick=${() =>
        this.showHidePR(pr)}>Add Back</a>
      </p>`
    }
    return hx`<article class="PR">
      <div class="PR-header">
        <h4 class="PR-heading">
          <a href="${pr.html_url}" class="PR-headingAnchor" target="_blank">
            ${pr.title}
          </a>
        </h4>
        <ul class="PR-metaList">
          <li class="PR-metaItem">
            <a href="${pr.html_url}" class="PR-metaAnchor" target="_blank">
              merged
              <time dateTime='${pr.merged_at}'>
                ${formatDate(new Date(pr.merged_at), 'DD MMM YYYY')}
              </time>
            </a>
          </li>
          <li class="PR-metaItem">
            <button type="button" class="Close Close--text" onclick=${() =>
              this.showHidePR(pr)}>
              ${renderIcon('close', 'Close-icon')}
              hide
            </button>
          </li>
        </ul>
      </div>
      ${this.renderPrContent(pr)}
      <div class="PR-footer">
        ${this.renderBranches(this.repo, pr.tags)}
      </div>
    </article>`
  }

  renderPrContent(pr) {
    if (pr.body !== null && pr.body.length > 0) {
      return hx`<div class="PR-content">
        ${hyperdom.rawHtml('div', renderMarkdown(pr.body))}
      </div>`
    }
  }

  renderBranches(repoPath, prTags) {
    if (prTags) {
      if (prTags.length > 0) {
        return renderTags({
          tags: prTags.map(tag => {
            return {
              label: tag,
              url: GitHub.buildBranchUrl(repoPath, tag)
            }
          }),
          label: 'merged to'
        })
      }
      return
    }
    return hx`<small>(loading branches)</small>`
  }

  render() {
    this.refresh = h.refresh
    if (this.savingReport) {
      return renderLoading('Saving report…')
    }
    const saveDisabled = this.loadingTags ? 'disabled' : ''
    const saveTitle = this.loadingTags ? 'Loading tags...' : ''
    return hx`<div class="Report">
      <div class="Page-panel">
        <header class="Report-header u-mb15">
          <h2 class="Report-heading">
            ${formatDate(this.startDate, 'Do MMM YYYY')} to ${formatDate(
      this.endDate,
      'Do MMM YYYY'
    )} ${this.datePicker}
          </h2>
          <div>
            <button type="button" disabled=${saveDisabled} title=${saveTitle} class="Button Button--primary Button--small" onclick=${() =>
      this.handleSaveClick()}>${
      this.reportId ? 'Update Report' : 'Save Report'
    }</button>
          </div>
        </header>
        <section>
          <h3 class="Page-sectionHeading">Report Summary</h3>
          <div class="FormField u-mb20">
            <label class="u-hiddenVisually" for="report-summary">Report Summary</label>
            <textarea class="Report-summaryInput FormField-input" id="report-summary" placeholder="e.g. This week we've been focusing on addressing technical debt and…" binding=${[
              this,
              'summary'
            ]}></textarea>
          </div>
        </section>
        <section>
          <h3 class="Page-sectionHeading${
            this.pullRequests.length === 0 ? ' u-mb15' : ''
          }">
            Merged Changes
          </h3>
          ${this.renderChangesList()}
        </section>
      </div>
    </div>`
  }
}
