class GitHub {
  buildBranchUrl(fullPath, branch) {
    const repoPath = fullPath
      .split('/')
      .slice(1)
      .join('/')
    return `https://github.com/${repoPath}/tree/${branch}`
  }
}

module.exports = new GitHub()
