const hx = require('hyperdom/hyperx')

module.exports = class TermsView {
  render() {
    return hx`<div class="Container">
      <div class="PageHeader">
        <h1 class="PageHeader-heading">Terms of Use</h1>
      </div>
      <div class="PageContents">
        <p>Users of the Shipping Report service must abide by this policy.</p>
        <ol>
          <li>You must not submit any content to Shipping Report that is illegal, offensive, or otherwise harmful.</li>
          <li>You must not submit any content to Shipping Report in violation of law, infringing the intellectual property rights of others, violating the privacy or other rights of others, or in violation of any agreement with a third party.</li>
          <li>You must not disclose information via Shipping Report that you do not have the right to disclose, such as confidential information of others.</li>
          <li>You must not copy or share any personally identifiable information of any other person through Shipping Report without their specific permission.</li>
          <li>You must not violate any applicable law in using Shipping Report.</li>
          <li>You must not use or attempt to use another person's Shipping Report account without their specific permission.</li>
          <li>You must not send advertisements, chain letters, or other solicitations via Shipping Report.</li>
          <li>You must not automate access to, use, or monitor the Shipping Report website, such as with a web crawler, browser plug-in or add-on, or other computer program that is not a web browser.</li>
          <li>You must not use Shipping Report to send e-mail to distribution lists, newsgroups, or group mail aliases.</li>
          <li>You must not falsely imply that you are affiliated with or endorsed by Shipping Report.</li>
          <li>You must not operate illegal schemes, such as pyramid schemes, via Shipping Report.</li>
          <li>You must not remove any marking indicating proprietary ownership from any material received from Shipping Report.</li>
          <li>You must not display any portion of the Shipping Report website via an HTML IFRAME.</li>
          <li>You must not disable, avoid, or circumvent any security or access restrictions of Shipping Report, or access parts of Shipping Report not intended for access by you.</li>
          <li>You must not strain infrastructure of Shipping Report with an unreasonable volume of requests, or requests designed to impose an unreasonable load on IT systems underlying Shipping Report.</li>
          <li>You must not encourage or assist any other person in violation of this acceptable use policy.</li>
        </ol>
      </div>
    </div>`
  }
}
