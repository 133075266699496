require('../styles/components/modal.css')
const hx = require('hyperdom/hyperx')
const HyperdomModal = require('hyperdom-modal')
const renderIcon = require('../partials/icon')

module.exports = class Modal extends HyperdomModal {
  renderContent({ content, classNames, onCancel }) {
    return this.render(
      {
        dialogOptions: { class: `Modal ${classNames}` },
        onCancel
      },
      hx`<div>
        <div class="Modal-content">
          ${content}
        </div>
        <button class="Modal-close Close" type="button" title="Close" onclick=${() =>
          this.close()}>
          ${renderIcon('close', 'Close-icon')}
          <span class="u-hiddenVisually">Close</span>
        </button>
      </div>`
    )
  }
}
