require('../styles/components/icon.css')
const h = require('hyperdom')

module.exports = (icon, classes) => {
  classes = classes || ''
  return h.rawHtml(
    '.Icon.' + classes,
    '<svg><use xlink:href="#i-' + icon + '"></use></svg>'
  )
}
