const debounce = require('debounce-promise')
const hx = require('hyperdom/hyperx')
const link = require('../partials/link')
const renderLoading = require('../partials/loading')

module.exports = class ProjectLookup {
  constructor({ server }) {
    this.server = server
    this.results = []
    this.isValid = false
  }

  render() {
    return hx`<div>
      <input class="FormField-input" id="repository-search" type="search" binding=${[
        this,
        'value'
      ]} placeholder="Search project e.g. React" />
      ${this.renderResults()}
    </div>`
  }

  renderResults() {
    if (this.inProgress) {
      return renderLoading('Searching')
    }
    if (this.value && this.results.length === 0) {
      return hx`<p>No results</p>`
    }
    if (this.results.length > 0) {
      return hx`<ul class="ResultsList">${this.results.map(name => {
        return hx`<li class="ResultsList-item">
            ${link(`/github/${name}`, name, {
              class: 'ResultsList-itemAnchor'
            })}
          </li>`
      })}</ul>`
    }
  }

  async search() {
    if (this.value) {
      this.inProgress = true
      const result = await this.server.searchGithubRepos(this.value)
      this.results = result.items.map(item => item.full_name)
    } else {
      this.results = []
    }
    this.inProgress = false
    this.refresh()
  }

  onadd(element) {
    const debouncedSearch = debounce(() => {
      return this.search()
    }, 200)
    element
      .querySelector('input[type=search]')
      .addEventListener('input', () => {
        this.inProgress = true
        debouncedSearch()
      })
  }
}
