const hx = require('hyperdom/hyperx')
const link = require('./partials/link')
const renderIcon = require('./partials/icon')

module.exports = ({ content, headerNav, footerNav }) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  return hx`<div class="App">
    <header class="AppHeader">
      <div class="Container">
        <div class="AppHeader-inner">
          ${link(
            '/',
            [
              renderIcon('ship-logo', 'AppHeader-logoIcon'),
              hx`<span class="AppHeader-logoText">Shipping Report</span>`
            ],
            { class: 'AppHeader-logo', title: 'Shipping Report' }
          )}
          ${headerNav}
        </div>
      </div>
    </header>
    <main class="AppContent">
      ${content}
    </main>
    <footer class="AppFooter">
      <div class="Container">
        <div class="AppFooter-inner">
          ${footerNav}
          <p class="AppFooter-text">© ${currentYear} <a class="AppFooter-textAnchor" href="https://www.featurist.co.uk/">Featurist Ltd.</a> - All Rights Reserved</p>
        </div>
      </div>
    </footer>
  </div>`
}
