require('../styles/components/flatpickr.css')
const flatpickr = require('flatpickr')
const hx = require('hyperdom/hyperx')

module.exports = class DatePicker {
  constructor(options) {
    this.dorender = options.render
    this.onchange = options.onchange
    this.defaultDate = options.defaultDate
  }

  onadd(element) {
    this.picker = flatpickr(element, {
      defaultDate: this.defaultDate,
      disableMobile: true,
      mode: 'range',
      animate: false,
      onChange: date => {
        const [startDate, endDate] = date
        if (startDate && endDate) {
          this.onchange(startDate, endDate, this.picker)
          this.refresh()
        }
      }
    })
  }

  render() {
    return hx`<span onclick=${() =>
      this.picker.open()}>${this.dorender()}</span>`
  }
}
