const endOfWeek = require('date-fns/end_of_week')
const h = require('hyperdom').html
const hx = require('hyperdom/hyperx')
const idWithoutService = require('../idWithoutService')
const link = require('../partials/link')
const renderLoading = require('../partials/loading')
const Report = require('../report')
const startOfWeek = require('date-fns/start_of_week')

module.exports = class ReportView {
  constructor({ today, server }) {
    this.today = today
    this.server = server
    this.model = {}
  }

  async loadReport({ reportId, repo }) {
    const data = await this.server.getReportData(repo)
    if (reportId) {
      const savedData = await this.server.loadReport(repo, reportId)
      savedData.pullRequests = data.pullRequests
      return savedData
    }
    return data
  }

  async onload(params) {
    const reportId = params.reportId
    const repo = `${params.service}/${params.org}/${params.repo}`
    const existingReport =
      reportId && this.report && reportId === this.report.reportId
    if (existingReport) {
      return
    }

    this.loadingReport = true
    const data = await this.loadReport({ repo, reportId })
    if (data.proRequired) {
      this.proRequired = true
    } else {
      this.report = new Report(
        {
          repo,
          pullRequests: data.pullRequests,
          excludedPRs: data.excludedPRs,
          startDate:
            (data.startDate && new Date(data.startDate)) ||
            startOfWeek(this.today, { weekStartsOn: 1 }),
          endDate:
            (data.endDate && new Date(data.endDate)) ||
            endOfWeek(this.today, { weekStartsOn: 1 }),
          reportViewCount: data.viewCount,
          reportId: data.reportId,
          internalId: data.internalId,
          summary: data.summary
        },
        this.server
      )
      if (!params.reportId) {
        this.loadBranchCommits(this.report)
      }
    }
    this.loadingReport = false
  }

  loadBranchCommits(report) {
    report.loadingTags = true
    const prDates = report.pullRequests.map(pr => pr.merged_at).sort()
    const since = prDates[0]

    const component = this
    this.server.getBranchCommits(report.repo, since).then(branches => {
      report.pullRequests.forEach(pr => {
        pr.tags = []
        Object.keys(branches).forEach(branch => {
          const commits = branches[branch]
          if (commits.indexOf(pr.sha) !== -1) {
            pr.tags.push(branch)
          }
        })
      })
      report.loadingTags = false
      component.refresh()
    })
  }

  renderHeaderBar() {
    return hx`<div class="HeaderBar">
      <div class="Container">
        <div class="HeaderBar-inner">
          <h1 class="HeaderBar-heading">${idWithoutService(
            this.report.repo
          )}</h1>
          <div class="HeaderBar-controls">
            ${link(`/${this.report.repo}`, 'View Project', {
              class: 'Button Button--outlinePrimary Button--small'
            })}
          </div>
        </div>
      </div>
    </div>`
  }

  render() {
    this.refresh = h.refresh
    if (this.loadingReport) {
      return renderLoading('Loading report…')
    }
    if (this.proRequired) {
      return hx`<div class="Container">
        <p class="Alert Alert--warning u-mt10">Private repos can only be used with a Pro Account.</p>
      </div>`
    }
    if (this.report) {
      return hx`<div>
        ${this.renderHeaderBar()}
        <div class="Container">
          <div class="PageHeader">
            <p class="PageHeader-subtext">
              ${
                this.report.reportId
                  ? 'Edit your report below then update to publish changes.'
                  : 'Modify your report below then save to get a share link.'
              }
            </p>
          </div>
          ${this.report}
        </div>
      </div>`
    }
    return hx`<div class="Container">
      <p class="Alert Alert--danger u-mt10">No repo "${this.repo}" found.</p>
    </div>`
  }
}
