require('babel-polyfill')
require('./styles/app.css')
const App = require('./app')
const hyperdom = require('hyperdom')
const router = require('hyperdom/router')

// eslint-disable-next-line
const __svg__ = {
  path: '../server/public/assets/images/svg-icons/*.svg',
  name: 'icon-sprite.svg'
}

window.addEventListener('load', () => {
  const domBody = document.body
  hyperdom.append(domBody, new App(), { router })
  domBody.classList.remove('is-loading')
})
