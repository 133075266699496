require('../styles/components/loading.css')
const hx = require('hyperdom/hyperx')
const renderIcon = require('./icon')

module.exports = (loadingText = 'Loading…') => {
  return hx`<div class="Loading" key="${loadingText}">
    ${renderIcon('ship-logo', 'Loading-icon')}
    <p class="Loading-heading">${loadingText}</p>
  </div>`
}
