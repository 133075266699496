const hx = require('hyperdom/hyperx')

module.exports = class PrivacyView {
  render() {
    return hx`<div class="Container">
      <div class="PageHeader">
        <h1 class="PageHeader-heading">Privacy Policy</h1>
      </div>
      <div class="PageContents">
        <p>We will never sell your private data or information. We don't give out your private info to organizations that may sell it. We do though disclose some customer information to SAAS providers that we use in order to make the Shipping Report service functional. Those third party companies include Google Mail for any communication with us, Heroku for hosting and Mailgun for notifications - each of these has their own privacy policies.</p>
        <h2>Your Data</h2>
        <p>Shipping Report does not access your code but we do use the OAuth access token provided by your SCM to read your pull/merge requests as well as branch/commit SHAs. No human ever reads your data in the normal order of business operations. Only the founders and background-checked employees have access to your data, and we will only look at it in response to support requests with your explicit permission. In the future, customer support level people may also be given access to data, but your data will never be shared with third party contractors. When sharing a report you are explicitly making your data available to anyone with the unique report url.</p>
        <h2>Security</h2>
        <p>All access to account information and repository data is conducted over SSL connection, and all passwords are 1-way encrypted, never plaintext. Shipping Report admins will never access your sensitive data unless you specifically authorize us to do so, and Shipping Report admins have no access to your password.</p>
        <h2>GitHub</h2>
        <p>The Shipping Report badge for your repository has to be placed into the “read me” by the user, the Shipping Report application does not access or change that file. When you sign up for Shipping Report you are explicitly allowing Shipping Report to access your repositories on Github, revoking this access is easy, just click the revoke button any time through your GitHub application settings page. Of course doing this will stop Shipping Report from having access to your private repositories.</p>
        <h2>Cancelling</h2>
        <p>If you wish to remove a repository, click the delete repository button. Any existing reports will continue to be accessible.</p>
        <p>If you would like to delete your account or completely remove a repository then please email <a href="mailto:support@shippingreport.com">support@shippingreport.com</a> from the email address the account is registered to.</p>
        <p>To remove access to your GitHub account, you can remove it via your GitHub application settings page.</p>
        <h2>Partners</h2>
        <p>Shipping Report is hosted on Heroku and orchestrated via Semaphore. If the Heroku service becomes vulnerable, your pull request data may also become vulnerable to accidental disclosure. Herokus Security Policy discusses their security in great detail.</p>
        <h2>Feedback</h2>
        <p>We take security incredibly seriously. If you have any suggestions for how we could improve our security, or improve this policy, please contact us at <a href="mailto:support@shippingreport.com">support@shippingreport.com</a>. We will act immediately to deal with the issue.</p>
      </div>
    </div>`
  }
}
