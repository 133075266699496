const hx = require('hyperdom/hyperx')
const renderLoading = require('../partials/loading')

module.exports = class Subscribe {
  constructor({ server, project, title = 'Subscribe to Weekly Updates' }) {
    this.server = server
    this.project = project
    this.title = title
  }
  render() {
    return hx`<form class="Well" onsubmit=${e =>
      this.subscribeEmail(e, this.subscriptionEmail)}>
      <h2 class="Page-sectionHeading u-textCenter">${this.title}</h3>
      ${this.subscribeInProgress ? renderLoading('Subscribing…') : undefined}
      ${
        this.subscribeSuccess
          ? hx`<p class="Alert Alert--success">
              We've received your request, now please activate your subscription
              by clicking on the link we've just emailed you.</p>`
          : undefined
      }
      ${
        this.subscribeInProgress
          ? null
          : hx`<div class="InlineForm">
              <div class="InlineForm-formField FormField u-mb10">
                <label class="u-hiddenVisually" for="subscriber-email">Email</label>
                <input class="FormField-input" id="subscriber-email" required="required" type="email" binding=${[
                  this,
                  'subscriptionEmail'
                ]} placeholder="you@example.com" />
              </div>
              <button type="submit" class="InlineForm-button Button Button--primary" disabled=${
                this.subscribeInProgress ? 'disabled' : ''
              }>Subscribe</button>
            </div>`
      }
    </form>`
  }

  async unSubscribe() {
    await this.server.unSubscribe({ repo: this.project.repoName })
    this.isSubscribed = false
  }

  async subscribe() {
    await this.server.subscribe({ repo: this.project.repoName })
    this.isSubscribed = true
  }

  async subscribeEmail(e, email) {
    e.preventDefault()
    this.subscribeInProgress = true
    await this.server.subscribe({ repo: this.project.repoName, email })
    delete this.subscribeInProgress
    this.subscribeSuccess = true
  }
}
