const marked = require('marked')

module.exports = code => {
  const renderer = new marked.Renderer()
  const renderListitem = marked.Renderer.prototype.listitem
  renderer.listitem = function(text) {
    let html = renderListitem(text)

    if (html.includes('<input')) {
      html = html.replace('<li>', "<li class='PR-taskListItem'>")
    }

    return html
  }

  if (code) {
    return marked(code, { renderer })
  }
  return ''
}
