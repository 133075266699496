const formatDate = require('date-fns/format')
const hx = require('hyperdom/hyperx')
const idWithoutService = require('../idWithoutService')
const Subscribe = require('./subscribe')

module.exports = class PublicProjectView {
  constructor({ server, project, reports }) {
    this.server = server
    this.reports = reports
    this.project = project
    this.subscribe = new Subscribe({ server, project })
  }

  renderHeaderBar() {
    return hx`<div class="HeaderBar u-mb20">
      <div class="Container">
        <div class="HeaderBar-inner">
          <h1 class="HeaderBar-heading">${idWithoutService(
            this.project.repoName
          )}</h1>
        </div>
      </div>
    </div>`
  }

  render() {
    return hx`<div>
      ${this.renderHeaderBar()}
      <div class="Container">
        <h2 class="Page-sectionHeading">Reports</h2>
        ${this.reports.length ? this.renderReports() : this.renderNoReports()}
        ${this.subscribe}
      </div>
    </div>`
  }

  renderNoReports() {
    if (this.reports.length === 0) {
      return hx`<p class="Alert Alert--info u-mb25">
        No reports are currently available for this project.
      </p>`
    }
  }

  renderReports() {
    return hx`<ul class="LinkList u-mb25">
      ${this.reports.map(report => {
        const reportTitle = `${formatUTCDate(
          report.startDate,
          'Do MMM YYYY'
        )} - ${formatUTCDate(report.endDate, 'Do MMM YYYY')}`
        const [service, org, repo, date] = report.reportId.split('/')
        const reportUrl = `/${service}/${org}/${repo}/reports/${date}`
        return hx`<li class="LinkList-item">
          <a href="${reportUrl}" class="LinkList-itemAnchor">
            ${reportTitle}
          </a>
        </li>`
      })}
    </ul>`
  }
}

function formatUTCDate(date, dateFormat) {
  date = new Date(date)
  const newDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  )
  return formatDate(newDate, dateFormat)
}
