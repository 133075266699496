const httpism = require('httpism/browser')

module.exports = class HTTPServerClient {
  constructor(http = httpism) {
    this.http = http
  }

  getUser() {
    return this.http.get('/user')
  }

  getUserRepos(refresh) {
    return this.http.get('/user/repos', {
      params: {
        refresh
      }
    })
  }

  getProjects() {
    return this.http.get('/user/projects')
  }

  getPopularProjects() {
    return this.http.get('/popular-projects')
  }

  getUserReposActive() {
    return this.http.get(`/user/repos/active`)
  }

  updateUserReposActive(repo) {
    return this.http.put(`/user/repos/active/${repo}`)
  }

  deleteUserReposActive(repo) {
    return this.http.delete(`/user/repos/active/${repo}`)
  }

  loadReport(repo, reportId) {
    return this.http.get(`/${repo}/reports/${reportId}.json`)
  }

  getReportData(repo) {
    return this.http.get(`/${repo}/pulls.json`)
  }

  getBranchCommits(repo, since) {
    return this.http.get(`/${repo}/commits.json`, {
      params: {
        since
      }
    })
  }

  emailReport(body) {
    return this.http.post('/email', body)
  }

  saveReport(report) {
    if (report.internalId || report.reportId) {
      return this.http.put(
        `/${report.repo}/reports/${report.internalId || report.reportId}.json`,
        report
      )
    }
    return this.http.post(`/${report.repo}/reports`, report)
  }

  getSharedReport({ reportId }) {
    return this.http.get('/shared-report/:reportId', {
      params: { reportId }
    })
  }

  startTrial(org) {
    return this.http.post(
      '/start-trial/:org',
      {},
      {
        params: { org }
      }
    )
  }

  getReports(repo) {
    return this.http.get(`/${repo}/reports.json`)
  }

  async getProject(repo) {
    const response = await this.http.get(`/${repo}/project.json`, {
      exceptions: false
    })
    if (response.statusCode === 200) {
      return response.body
    }
  }

  logout() {
    return this.http.get('/logout')
  }

  authoriseGithubUser() {
    return this.http.get('/login-callback')
  }

  subscribe({ repo, email }) {
    return this.http.post(`/${repo}/subscription`, { email })
  }

  unSubscribe({ repo, subscriptionId }) {
    if (repo) {
      return this.http.delete(`/${repo}/subscription`)
    }
    if (subscriptionId) {
      return this.http.delete(`/subscription/${subscriptionId}`)
    }
    throw new Error(
      'Must call unsubscribe with either a repo or subscriptionId'
    )
  }

  isSubscribed({ repo }) {
    return this.http.get(`/${repo}/is-subscribed`)
  }

  activateSubscription({ subscriptionId }) {
    return this.http.post(`/activate-subscription/${subscriptionId}`)
  }

  searchGithubRepos(input = '') {
    const parts = input.split('/')
    const org = parts.length === 2 ? parts[0] : undefined
    const name = parts.length === 2 ? parts[1] : parts[0]
    const orgQuery = org ? `org:${org}` : ''
    const q = `in:name ${name} ${orgQuery}`

    return this.http.get(`https://api.github.com/search/repositories?q=${q}`)
  }
}
