const hx = require('hyperdom/hyperx')
const PrivateProjectView = require('./privateProject')
const PublicProjectView = require('./publicProject')
const renderLoading = require('../partials/loading')

module.exports = class ProjectView {
  constructor({ server }) {
    this.server = server
  }

  async onload(params) {
    this.repo = `${params.service}/${params.org}/${params.repo}`
    this.loadingProject = true
    const options = {
      project: await this.server.getProject(this.repo),
      reports: await this.server.getReports(this.repo),
      server: this.server
    }
    delete this.loadingProject
    if (history.state) {
      this.savedReportId = history.state.savedReportId
      this.activeReportId = this.savedReportId
    }
    if (options.project.openSource) {
      this.projectView = new PublicProjectView(options)
    } else {
      this.projectView = new PrivateProjectView(options)
    }
  }

  render() {
    if (this.loadingProject) {
      return renderLoading('Loading project…')
    }
    if (!this.projectView) {
      return hx`<div class="Container">
        <div class="PageHeader">
          <h1 class="PageHeader-heading">Not Found</h1>
        </div>
        <p class="Alert Alert--danger">Sorry this project could not be found.</p>
      </div>`
    }
    return this.projectView
  }
}
