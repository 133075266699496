const hx = require('hyperdom/hyperx')
const projectCard = require('../partials/projectCard')
const ProjectLookup = require('../partials/projectLookup')
const renderLoading = require('../partials/loading')

module.exports = class ExploreView {
  constructor({ server }) {
    this.server = server
    this.search = new ProjectLookup({ server })
  }

  async onload() {
    this.popularProjects = await this.server.getPopularProjects()
  }

  renderHeaderBar() {
    return hx`<div class="HeaderBar">
      <div class="Container">
        <div class="HeaderBar-inner">
          <h1 class="HeaderBar-heading">Popular Projects</h1>
          <div class="HeaderBar-controls">
            <a href="#search" class="Button Button--outlinePrimary Button--small">Search</a>
          </div>
        </div>
      </div>
    </div>`
  }

  renderProjects() {
    return hx`<div>
      ${this.renderHeaderBar()}
      <div class="Container">
        <div class="PageHeader">
          <p class="PageHeader-subtext"></p>
        </div>
        <ul class="HeroList">
          ${this.popularProjects.map(project => {
            return projectCard(project)
          })}
        </ul>
        <div class="Jumbotron">
          <div class="Container">
            <a name="search"></a>
            <h2 class="Jumbotron-heading">Find another project</h2>
            <div class="Well">
              <div class="FormField">
                <label class="u-hiddenVisually" for="repository-search">Search Projects</label>
                ${this.search}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`
  }
  render() {
    if (!this.popularProjects) {
      return renderLoading()
    }
    return this.renderProjects()
  }
}
