const hx = require('hyperdom/hyperx')

module.exports = class HelpView {
  render() {
    return hx`<div class="Container">
      <div class="PageHeader">
        <h1 class="PageHeader-heading">Help</h1>
      </div>
      <div class="PageContents">
        <p>Thank you for using Shipping Report.</p>
        <p>If you experience any problems or have any feedback then please email us <a href="mailto:support@shippingreport.com">support@shippingreport.com</a>.</p>
      </div>
    </div>`
  }
}
