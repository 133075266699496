const hx = require('hyperdom/hyperx')

module.exports = (href, contents, attributes = {}) => {
  return hx`<a href="${href}" ${attributes} onclick=${e => {
    e.preventDefault()
    window.history.pushState(null, null, href)
    if (window.trackPageView) {
      window.trackPageView()
    }
  }}>${contents}</a>`
}
