const hx = require('hyperdom/hyperx')

module.exports = ({ tags, label }) => {
  return hx`<div class="Tags">
    <p class="Tags-label">${label}</p>
    <ul class="Tags-list">
      ${tags.map(tag => {
        return hx`<li class="Tags-listItem">
          <a href="${tag.url}" class="Tags-listAnchor" target="_blank">
            ${tag.label}
          </a>
        </li>`
      })}
    </ul>
  </div>`
}
