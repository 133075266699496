const hx = require('hyperdom/hyperx')
const renderLoading = require('../partials/loading')

module.exports = class ActivateSubscription {
  constructor({ server }) {
    this.server = server
  }

  async onload({ subscriptionId }) {
    const { activated } = await this.server.activateSubscription({
      subscriptionId
    })
    this.activated = activated
  }

  render() {
    return hx`<div>
      <div class="HeaderBar u-mb20">
        <div class="Container">
          <div class="HeaderBar-inner">
            <h1 class="HeaderBar-heading">Subscription</h1>
          </div>
        </div>
      </div>
      <div class="Container">
        ${this.renderActivating()}
      </div>
    </div>`
  }

  renderActivating() {
    if (this.activated === undefined) {
      return renderLoading('Activating subscription…')
    }
    if (this.activated) {
      return hx`<div>
        <p class="Alert Alert--success">Subscription Activated</p>
        <p class="Alert Alert--info">You will receive a report every Monday morning.</p>
      </div>`
    }
    return hx`<p class="Alert Alert--danger">Subscription Not Found</p>`
  }
}
