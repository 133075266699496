const hx = require('hyperdom/hyperx')
const link = require('../partials/link')
const ProjectLookup = require('../partials/projectLookup')
const renderIcon = require('../partials/icon')

module.exports = class HomeView {
  constructor({ exampleReportModal, server }) {
    this.exampleReportModal = exampleReportModal
    this.search = new ProjectLookup({ server })
  }

  signInButton(type) {
    const query = this.redirectUrl ? `?redirect_url=${this.redirectUrl}` : ''
    const classes = `Button Button--large ${type}`
    if (this.user) {
      return link('/projects', 'View Projects', { class: classes })
    }
    return hx`<a class="${classes}" href='/login${query}'>Connect with GitHub</a>`
  }

  renderVideoPlayer() {
    return hx`<div class="HomeVideo FlexEmbed">
      <div class="FlexEmbed-ratio FlexEmbed-ratio--16by9"></div>
      <div class="FlexEmbed-content">
        ${
          this.showVideo
            ? hx`<iframe class="HomeVideo-embed" src="https://www.youtube.com/embed/mJzxCNEfVbY?autoplay=1&autohide=1&modestbranding=1&showinfo=0&controls=1&vq=hd720" allow="autoplay" allowFullscreen frameborder="0"></iframe>`
            : hx`<a href="http://www.youtube.com/embed/mJzxCNEfVbY" class="HomeVideo-link" onclick=${e => {
                e.preventDefault()
                this.showVideo = true
              }}>
              <img class="HomeVideo-linkThumb" width="410" height="230" src="assets/images/home-video-thumb.jpg" alt="Shipping Report video thumb"/>
              ${renderIcon('play', 'HomeVideo-linkIcon')}
            </a>`
        }
      </div>
    </div>`
  }

  render() {
    return hx`<div>
      <div class="Jumbotron Jumbotron--pageTop">
        <div class="Container">
          <h1 class="Jumbotron-lead">
            Communicate <span class="u-textBold">project progress</span><br>
            from developer activity<br>
            with <span class="u-textBold">just a few clicks</span>
          </h1>
          ${this.signInButton('Button--secondary')}
        </div>
      </div>
      <div class="Container">
        ${this.renderVideoPlayer()}
      </div>
      <div class="Heros">
        <div class="Container">
          <div class="Heros-inner">
            <div class="Heros-item">
              ${renderIcon('tag', 'Heros-itemIcon')}
              <h3 class="Heros-itemHeading">Focus on the Features</h3>
              <p>Use the developers natural way of working to report on their progress.</p>
            </div>
            <div class="Heros-item">
              ${renderIcon('pulse', 'Heros-itemIcon')}
              <h3 class="Heros-itemHeading">Report in Seconds</h3>
              <p>Select a project, customise settings and share the result with a few simple clicks.</p>
            </div>
            <div class="Heros-item">
              ${renderIcon('file', 'Heros-itemIcon')}
              <h3 class="Heros-itemHeading">Communicate What Matters</h3>
              <p>Give your stakeholders clear updates for shipped features, complete with screenshots.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="Jumbotron Jumbotron--white">
        <div class="Container">
          <h2 class="Jumbotron-heading">Project progress reports from Shipping Report help improve insight</h2>
          <div class="ButtonPair">
            <div class="ButtonPair-panel">
              ${link('/how-it-works', 'How It Works', {
                class: 'Button Button--outlinePrimary Button--large'
              })}
            </div>
            <div class="ButtonPair-panel">
              <button key="email-button" class="Button Button--primary Button--large" onclick=${() =>
                this.exampleReportModal.open()}>Example Report</button>
            </div>
          </div>
        </div>
      </div>
      <div class="Jumbotron">
        <div class="Container">
          <h2 class="Jumbotron-heading">View Open Source projects</h2>
          <div class="Well">
            <div class="FormField">
              <label class="u-hiddenVisually" for="repository-search">Search Projects</label>
              ${this.search}
            </div>
          </div>
        </div>
      </div>
      <div class="Jumbotron Jumbotron--white">
        <div class="Container">
          <h2 class="Jumbotron-heading">Get started with a Shipping Report in less than 10 seconds</h2>
          ${this.signInButton('Button--secondary')}
        </div>
      </div>
    </div>`
  }
}
