const clipboardCopy = require('clipboard-copy')
const formatDate = require('date-fns/format')
const hx = require('hyperdom/hyperx')
const idWithoutService = require('../idWithoutService')
const link = require('../partials/link')
const Modal = require('../components/Modal')
const renderIcon = require('../partials/icon')
const renderLoading = require('../partials/loading')
const Subscribe = require('./subscribe')

module.exports = class PrivateProjectView {
  constructor({ server, project, reports }) {
    this.server = server
    this.reports = reports.filter(
      report => report.userName !== 'auto scheduler'
    )
    this.automaticReports = reports.filter(
      report => report.userName === 'auto scheduler'
    )
    this.project = project
    this.emailReportModal = new Modal()
    this.subscribe = new Subscribe({
      server,
      project,
      title: 'Subscribe a friend to weekly updates'
    })
  }

  async onload() {
    if (history.state) {
      this.savedReportId = history.state.savedReportId
      this.activeReportId = this.savedReportId
    }
    this.isSubscribed = await this.server.isSubscribed({
      repo: this.project.repoName
    })
  }

  async unSubscribe() {
    await this.server.unSubscribe({ repo: this.project.repoName })
    this.isSubscribed = false
  }

  async subscribeSelf() {
    await this.server.subscribe({ repo: this.project.repoName })
    this.isSubscribed = true
  }

  async sendEmail() {
    this.sendingEmail = true
    this.emailSent = false

    try {
      await this.server.emailReport({
        reportId: this.activeReportId,
        recipients: this.recipients
      })
    } finally {
      this.sendingEmail = false
      this.emailSent = true
      delete this.recipients
    }
  }

  handleEmailClick() {
    this.emailReportModal.open()
  }

  handleCloseModal() {
    this.emailSent = false
    delete this.recipients
  }

  handleCopyLinkSubmit(e, shareLink) {
    e.preventDefault()
    clipboardCopy(shareLink)
    return (this.linkCopied = true)
  }

  handleSendEmailSubmit(e) {
    e.preventDefault()
    return this.sendEmail()
  }

  isActive(report) {
    return this.activeReportId === report.reportId
  }

  showHideReport(e, report) {
    e.preventDefault()
    delete this.linkCopied
    delete this.savedReportId
    if (this.isActive(report)) {
      return delete this.activeReportId
    }
    return (this.activeReportId = report.reportId)
  }

  renderHeaderBar() {
    return hx`<div class="HeaderBar u-mb20">
      <div class="Container">
        <div class="HeaderBar-inner">
          <h1 class="HeaderBar-heading">${idWithoutService(
            this.project.repoName
          )}</h1>
          ${this.renderHeaderBarControls()}
        </div>
      </div>
    </div>`
  }

  renderHeaderBarControls() {
    return hx`<div class="HeaderBar-controls">
        ${
          this.isSubscribed
            ? hx`<button onclick=${() =>
                this.unSubscribe()} class="BellButton HeaderBar-bellButton is-active" title="You currently receive weekly reports every Monday morning - click to cancel.">
                ${renderIcon('bell-active', 'BellButton-icon')}
                <span class="u-hiddenVisually">You currently receive weekly reports every Monday morning - click to cancel.</span>
              </button>`
            : hx`<button onclick=${() =>
                this.subscribeSelf()} class="BellButton HeaderBar-bellButton" title="Receive weekly reports by email every Monday morning.">
                ${renderIcon('bell', 'BellButton-icon')}
                <span class="u-hiddenVisually">Receive weekly reports by email every Monday morning.</span>
              </button>`
        }
        ${link(`/${this.project.repoName}/reports/new`, 'New Report', {
          class: 'Button Button--outlinePrimary Button--small'
        })}
      </div>`
  }

  renderReportMeta(report) {
    const viewCountWeb = report.views.web || '0'
    return hx`<div>
      <div class="ReportMeta">
        ${
          this.savedReportId
            ? hx`<p class="Alert Alert--success Alert--small u-mb10">
                Report saved! Share your report below.
              </p>`
            : undefined
        }
        <div class="ReportMeta-inner">
          <div class="ReportMeta-innerColumn ReportMeta-innerColumn--heading">
            <h3 class="Page-sectionHeading">Link</h3>
          </div>
          <div class="ReportMeta-innerColumn ReportMeta-innerColumn--heading">
            <h3 class="Page-sectionHeading">Page Views</h3>
          </div>
          <div class="ReportMeta-innerColumn ReportMeta-innerColumn--heading">
            <h3 class="Page-sectionHeading">Actions</h3>
          </div>

          <div class="ReportMeta-innerColumn">
            ${this.renderShareLinkForm(report.internalId || report.reportId)}
          </div>

          <div class="ReportMeta-innerColumn">
            <span class="Views-web">Web: ${viewCountWeb}</span>
          </div>

          <div class="ReportMeta-innerColumn">
            <div class="ReportMeta-innerAction">
              ${link(
                `/${report.repo}/reports/edit/${report.internalId ||
                  report.reportId}`,
                'Edit',
                {
                  class: 'Button Button--primary Button--small'
                }
              )}
            </div>

            <div class="ReportMeta-innerAction">
              <button key="email-button" class="ReportMeta-innerAction Button Button--primary Button--small" onclick=${() =>
                this.handleEmailClick()}>Email Report</button>
            </div>
          </div>
        </div>
      </div>
      ${this.emailReportModal.renderContent({
        onCancel: this.handleCloseModal,
        content: hx`<div>
          <h3 class="u-mb10 u-textCenter">Email Report</h3>
          ${this.renderEmailForm()}
        </div>`
      })}
    </div>`
  }

  renderReportsAccordion() {
    if (this.reports.length > 0) {
      return hx`<ul class="Accordion u-mb20">
        ${this.reports.map(report => {
          const reportTitle = `${formatUTCDate(
            report.startDate,
            'Do MMM YYYY'
          )} - ${formatUTCDate(report.endDate, 'Do MMM YYYY')}`
          return hx`<li class=${{
            'Accordion-item': true,
            'is-active': this.isActive(report)
          }} data-lastsavedby=${report.userName}>
            <a class="Accordion-itemAnchor" onclick=${e =>
              this.showHideReport(
                e,
                report
              )} href="/shared-report/${report.internalId || report.reportId}">
              ${reportTitle}
              ${renderIcon('chevron-down', 'Accordion-itemIcon')}
            </a>
            ${this.isActive(report) ? this.renderReportMeta(report) : undefined}
          </li>`
        })}
      </ul>`
    }
    return hx`<p class="Alert Alert--info u-mb20">No reports for this project yet.</p>`
  }

  renderEmailForm() {
    if (this.sendingEmail) {
      return renderLoading('Sending report…')
    }
    return hx`<form onsubmit=${e => this.handleSendEmailSubmit(e)}>
      <div class="FormField u-mb10">
        <label class="FormField-label" for="email-recipients">Email To</label>
        <input class="FormField-input" id="email-recipients" required="required" type="text" binding=${[
          this,
          'recipients'
        ]} placeholder="e.g. your@email.com, second@email.com" />
      </div>
      <button type="submit" class="Button Button--primary Button--wide">Send Email</button>
      ${
        this.emailSent
          ? hx`<p class="Alert Alert--success Alert--small u-mt10">Report successfully sent.</p>`
          : undefined
      }
    </form>`
  }

  renderShareLinkForm(reportId) {
    const shareLink = `${window.location.origin}/shared-report/${reportId}`
    return hx`<form key="copy-link" onsubmit=${e =>
      this.handleCopyLinkSubmit(e, shareLink)}>
      <div class="ReportMeta-innerLink">
        <div class="FormField">
          <label class="u-hiddenVisually" for="share-link">Share Link</label>
          <input class="FormField-input" id="share-link" type="text" value=${shareLink} onfocus=${e =>
      e.target.select()} readOnly />
        </div>
        <button type="submit" class="Button Button--outlinePrimary Button--small">Copy</button>
        <a class="Button Button--outlinePrimary Button--small" href="/shared-report/${reportId}" target="_blank">View</a>
      </div>
      ${
        this.linkCopied
          ? hx`<p class="Alert Alert--success Alert--small u-mt10">Link copied to clipboard.</p>`
          : undefined
      }
    </form>`
  }

  renderAutomaticReports() {
    return hx`<ul class="LinkList u-mb25">
      ${this.automaticReports.map(report => {
        const reportTitle = `${formatUTCDate(
          report.startDate,
          'Do MMM YYYY'
        )} - ${formatUTCDate(report.endDate, 'Do MMM YYYY')}`
        const reportUrl = `/shared-report/${report.internalId}`
        return hx`<li class="LinkList-item">
          <a href="${reportUrl}" class="LinkList-itemAnchor">
            ${reportTitle}
          </a>
        </li>`
      })}
    </ul>`
  }

  render() {
    if (this.loadingProject) {
      return renderLoading('Loading project…')
    }

    if (!this.project) {
      return hx`<div class="Container">
        <div class="PageHeader">
          <h1 class="PageHeader-heading">Not Found</h1>
        </div>
        <p class="Alert Alert--danger">Sorry this project could not be found.</p>
      </div>`
    }

    return hx`<div>
      ${this.renderHeaderBar()}
      <div class="Container">
        <h2 class="Page-sectionHeading">Saved Reports</h2>
        ${this.renderReportsAccordion()}
        <h2 class="Page-sectionHeading">Automatic Reports</h2>
        ${this.renderAutomaticReports()}
        ${this.subscribe}
      </div>
    </div>`
  }

  renderNoReports() {
    if (this.reports.length === 0) {
      return hx`<p class="Alert Alert--info u-mb25">
        No reports are currently available for this project.
      </p>`
    }
  }

  renderSubscription() {
    return hx`<form class="Well" onsubmit=${e =>
      this.subscribeEmail(e, this.subscriptionEmail)}>
      <h2 class="Page-sectionHeading u-textCenter">Subscribe to Weekly Updates</h3>
      ${this.subscribeInProgress ? renderLoading('Subscribing…') : undefined}
      ${
        this.subscribeSuccess
          ? hx`<p class="Alert Alert--success">
              We've received your request, now please activate your subscription
              by clicking on the link we've just emailed you.</p>`
          : undefined
      }
      ${
        this.subscribeInProgress
          ? null
          : hx`<div class="InlineForm">
              <div class="InlineForm-formField FormField u-mb10">
                <label class="u-hiddenVisually" for="subscriber-email">Email</label>
                <input class="FormField-input" id="subscriber-email" required="required" type="email" binding=${[
                  this,
                  'subscriptionEmail'
                ]} placeholder="you@example.com" />
              </div>
              <button type="submit" class="InlineForm-button Button Button--primary" disabled=${
                this.subscribeInProgress ? 'disabled' : ''
              }>Subscribe</button>
            </div>`
      }
    </form>`
  }

  async subscribeEmail(e, email) {
    e.preventDefault()
    this.subscribeInProgress = true
    await this.server.subscribe({ repo: this.project.repoName, email })
    delete this.subscribeInProgress
    this.subscribeSuccess = true
  }
}

function formatUTCDate(date, dateFormat) {
  date = new Date(date)
  const newDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  )
  return formatDate(newDate, dateFormat)
}
