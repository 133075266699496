const hx = require('hyperdom/hyperx')
const link = require('../partials/link')
const projectCard = require('../partials/projectCard')
const renderIcon = require('../partials/icon')
const renderLoading = require('../partials/loading')

module.exports = class ProjectsView {
  constructor({ server }) {
    this.server = server
  }

  async onload() {
    this.activeProjects = await this.server.getProjects()
  }

  renderIntro() {
    return hx`<div class="Container">
      <div class="PageHeader">
        <h1 class="PageHeader-heading">No Active Projects</h1>
        ${renderIcon('ship-logo-no-sails', 'PageHeader-icon')}
        <p class="PageHeader-subtext u-mb20">Start your first Shipping Report by enabling a repository.</p>
        ${link('/repos/manage', 'Manage Repositories', {
          class: 'Button Button--primary'
        })}
      </div>
    </div>`
  }

  renderHeaderBar() {
    return hx`<div class="HeaderBar">
      <div class="Container">
        <div class="HeaderBar-inner">
          <h1 class="HeaderBar-heading">Projects</h1>
          <div class="HeaderBar-controls">
            ${link('/repos/manage', 'Manage Repositories', {
              class: 'Button Button--outlinePrimary Button--small'
            })}
          </div>
        </div>
      </div>
    </div>`
  }

  renderProjects() {
    return hx`<div>
      ${this.renderHeaderBar()}
      <div class="Container">
        <div class="PageHeader">
          <p class="PageHeader-subtext">Your Shipping Report projects are listed below.</p>
        </div>
        <ul class="HeroList">
          ${this.activeProjects.map(project => {
            return projectCard(project)
          })}
        </ul>
        <div class="u-textCenter u-mb20">
          ${link('/repos/manage', 'Add Another Repository', {
            class: 'Button Button--primary'
          })}
        </div>
      </div>
    </div>`
  }

  render() {
    if (!this.activeProjects) {
      return renderLoading()
    }
    if (this.activeProjects.length > 0) {
      return this.renderProjects()
    }
    return this.renderIntro()
  }
}
