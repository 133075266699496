const hx = require('hyperdom/hyperx')
const renderLoading = require('../partials/loading')

module.exports = class UnsubscribeView {
  constructor({ server }) {
    this.server = server
  }

  async onload({ subscriptionId }) {
    const { unsubscribed } = await this.server.unSubscribe({
      subscriptionId
    })
    this.unsubscribed = unsubscribed
  }

  render() {
    return hx`<div>
      <div class="HeaderBar u-mb20">
        <div class="Container">
          <div class="HeaderBar-inner">
            <h1 class="HeaderBar-heading">Subscription</h1>
          </div>
        </div>
      </div>
      <div class="Container">
        ${this.renderUnsubscribing()}
      </div>
    </div>`
  }

  renderUnsubscribing() {
    if (this.unsubscribed === undefined) {
      return renderLoading('Unsubscribing…')
    }
    if (this.unsubscribed) {
      return hx`<p class="Alert Alert--success">Unsubscribed</p>`
    }
    return hx`<p class="Alert Alert--danger">Subscription Not Found</p>`
  }
}
