const router = require('hyperdom/router')

module.exports = {
  howItWorks: router.route('/how-it-works'),
  help: router.route('/help'),
  home: router.route('/'),
  manageRepos: router.route('/repos/manage'),
  privacy: router.route('/privacy'),
  projects: router.route('/projects'),
  explore: router.route('/explore'),
  report: router.route('/:service/:org/:repo/reports/new'),
  editReport: router.route('/:service/:org/:repo/reports/edit/:reportId'),
  project: router.route('/:service/:org/:repo'),
  terms: router.route('/terms'),
  activateSubscription: router.route('/activate-subscription/:subscriptionId'),
  unsubscribe: router.route('/unsubscribe/:subscriptionId')
}
