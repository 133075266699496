const HttpServerClient = require('./httpServerClient')
const hx = require('hyperdom/hyperx')
const layout = require('./layout')
const link = require('./partials/link')
const routes = require('./routes')

const ActivateSubscriptionView = require('./views/activateSubscription')
const ExploreView = require('./views/explore')
const HelpView = require('./views/help')
const HomeView = require('./views/home')
const HowItWorksView = require('./views/howItWorks')
const ManageReposView = require('./views/manageRepos')
const Modal = require('./components/Modal')
const PrivacyView = require('./views/privacy')
const ProjectsView = require('./views/projects')
const ProjectView = require('./views/project')
const ReportView = require('./views/report')
const TermsView = require('./views/terms')
const UnsubscribeView = require('./views/unsubscribe')

function bindRoute(obj) {
  return {
    render() {
      return obj.render()
    },

    onload(params) {
      if (obj.onload) {
        return obj.onload(params)
      }
    }
  }
}

module.exports = class App {
  constructor({ server = new HttpServerClient(), today = new Date() } = {}) {
    this.server = server
    this.today = today
    this.exampleReportModal = new Modal()
    this.howItWorksView = new HowItWorksView()
    this.helpView = new HelpView()
    this.homeView = new HomeView({
      exampleReportModal: this.exampleReportModal,
      server
    })
    this.manageReposView = new ManageReposView({
      server,
      loadUser: this.loadUser.bind(this)
    })
    this.privacyView = new PrivacyView()
    this.projectsView = new ProjectsView({ server })
    this.exploreView = new ExploreView({ server })
    this.reportView = new ReportView({ today, server })
    this.projectView = new ProjectView({ server })
    this.termsView = new TermsView()
    this.activateSubscriptionView = new ActivateSubscriptionView({ server })
    this.unsubscribeView = new UnsubscribeView({ server })
  }

  async onload() {
    this.isLoadingUser = true
    await this.loadUser()
    this.isLoadingUser = false
  }

  async loadUser() {
    let resolveLoading
    const loading = new Promise(resolve => {
      resolveLoading = resolve
    })
    this.homeView.loadingApp = loading
    this.manageReposView.loadingApp = loading

    const { authorised, user } = await this.server.getUser()
    if (authorised) {
      this.user = user
      this.homeView.user = user
      this.manageReposView.user = user
    } else {
      this.user = null
    }
    await resolveLoading()
  }

  async logout() {
    await this.server.logout()

    delete this.user
    delete this.homeView.user
    delete this.manageReposView.user

    window.history.pushState(null, null, '/')
  }

  routes() {
    return [
      routes.howItWorks(bindRoute(this.howItWorksView)),
      routes.help(bindRoute(this.helpView)),
      routes.home(bindRoute(this.homeView)),
      routes.manageRepos(bindRoute(this.manageReposView)),
      routes.privacy(bindRoute(this.privacyView)),
      routes.projects(bindRoute(this.projectsView)),
      routes.explore(bindRoute(this.exploreView)),
      routes.report(bindRoute(this.reportView)),
      routes.editReport(bindRoute(this.reportView)),
      routes.project(bindRoute(this.projectView)),
      routes.terms(bindRoute(this.termsView)),
      routes.activateSubscription(bindRoute(this.activateSubscriptionView)),
      routes.unsubscribe(bindRoute(this.unsubscribeView))
    ]
  }

  renderHeaderNavList() {
    if (this.isLoadingUser) {
      return this.renderAuthLoadingHeaderNavList()
    }
    if (this.user) {
      return this.renderAuthenticatedHeaderNavList()
    }
    return this.renderAnonymousHeaderNavList()
  }

  renderAuthLoadingHeaderNavList() {
    return hx`<ul class="AppHeader-navList"></ul>`
  }

  renderAuthenticatedHeaderNavList() {
    return hx`<ul class="AppHeader-navList">
      <li class="AppHeader-navItem">${link('/explore', 'Explore', {
        class: 'AppHeader-navAnchor'
      })}</li>
      <li class="AppHeader-navItem">
        ${link('/projects', 'Projects', { class: 'AppHeader-navAnchor' })}
      </li>
      <li class="AppHeader-navItem">
        <a href="#" onclick=${e => {
          e.preventDefault()
          return this.logout()
        }} class="AppHeader-navAnchor">Log out</a>
      </li>
    </ul>`
  }

  renderAnonymousHeaderNavList() {
    return hx`<ul class="AppHeader-navList">
      <li class="AppHeader-navItem">
        ${link('/how-it-works', 'How It Works', {
          class: 'AppHeader-navAnchor'
        })}
      </li>
      <li class="AppHeader-navItem">${link('/explore', 'Explore', {
        class: 'AppHeader-navAnchor'
      })}</li>
      <li class="AppHeader-navItem u-hiddenMobile">
        <a href="#" onclick=${e => {
          e.preventDefault()
          this.exampleReportModal.open()
        }} class="AppHeader-navAnchor">Example Report</a>
      </li>
      <li class="AppHeader-navItem">
        <a href="/login" class="AppHeader-navAnchor">Login</a>
      </li>
    </ul>`
  }

  headerNav() {
    return [
      hx`<nav class="AppHeader-nav">
        ${this.renderHeaderNavList()}
      </nav>`,
      this.exampleReportModal.renderContent({
        classNames: 'Modal--large',
        content: hx`<div>
          <h3 class="u-mb10 u-textCenter">Example Report</h3>
          <iframe class="Modal-iframe" src="https://shippingreport.com/shared-report/jdz0ogxj000qe28b1a4mgpmj" width="400" height="300"></iframe>
        </div>`
      })
    ]
  }

  footerNav() {
    return hx`<nav class="AppFooter-nav">
      <ul class="AppFooter-navList">
        <li class="AppFooter-navItem">
          ${link('/how-it-works', 'How It Works', {
            class: 'AppFooter-navAnchor'
          })}
        </li>
        <li class="AppFooter-navItem">
          ${link('/help', 'Help', { class: 'AppFooter-navAnchor' })}
        </li>
        <li class="AppFooter-navItem">
          ${link('/privacy', 'Privacy', { class: 'AppFooter-navAnchor' })}
        </li>
        <li class="AppFooter-navItem">
          ${link('/terms', 'Terms', { class: 'AppFooter-navAnchor' })}
        </li>
      </ul>
    </nav>`
  }

  renderLayout(content) {
    return layout({
      content,
      headerNav: this.headerNav(),
      footerNav: this.footerNav()
    })
  }
}
