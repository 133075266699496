const hx = require('hyperdom/hyperx')
const hyperdom = require('hyperdom')
const link = require('../partials/link')

module.exports = function(project) {
  const repoName = project.repoName
    .split('/')
    .slice(1)
    .join('/')
  return hx`<li class="HeroList-item">
              <div class="HeroList-title">
                <img src="${project.avatarUrl}&s=96" width="25" height="25">
                ${link(`/${project.repoName}`, repoName, {
                  class: 'HeroList-itemAnchor'
                })}
                </div>
                <div class="HeroList-description">
                  ${hyperdom.rawHtml(
                    'div',
                    {},
                    project.descriptionHtml || project.description
                  )}
                </div>
              </div>
            </li>`
}
