const hx = require('hyperdom/hyperx')

module.exports = class HowItWorksView {
  render() {
    return hx`<div class="Page Page--white">
      <div class="Container">
        <div class="PageHeader">
          <h1 class="PageHeader-heading u-mb20">How It Works</h1>
          <p class="PageHeader-subtext">Shipping Report give stakeholders better insight into project progress.</p>
          <p class="PageHeader-subtext">Find out how we integrate with your existing developer workflow.</p>
        </div>
        <ol class="Steps">
          <li class="Steps-item Steps-item--lineRight">
            <div class="Steps-itemMedia">
              <img src="assets/images/avatar-developer.svg" alt="Developers" class="Steps-itemMediaImage" />
            </div>
            <div class="Steps-itemContent">
              <h2 class="Steps-itemHeading">Developers Work on Features</h2>
              <ul class="Steps-itemList">
                <li class="Steps-itemListItem">Developers continue to work as usual by pushing changes to GitHub.</li>
                <li class="Steps-itemListItem">When they add or update a feature they submit a Pull Request that include details about the change.</li>
              </ul>
            </div>
          </li>
          <li class="Steps-item Steps-item--reverse">
            <div class="Steps-itemMedia">
              <img src="assets/images/avatar-report.svg" alt="Project Managers" class="Steps-itemMediaImage" />
            </div>
            <div class="Steps-itemContent">
              <h2 class="Steps-itemHeading">Project Managers Build Reports</h2>
              <ul class="Steps-itemList">
                <li class="Steps-itemListItem">When required (ideally weekly) you can create a report for a specific time period.</li>
                <li class="Steps-itemListItem">Changes that occurred during this period are presented for inclusion in the report.</li>
                <li class="Steps-itemListItem">The relevant changes are selected, an executive summary is written and the report is saved.</li>
              </ul>
            </div>
          </li>
          <li class="Steps-item Steps-item--lineLeft">
            <div class="Steps-itemMedia">
              <img src="assets/images/avatar-stakeholder.svg" alt="Stakeholders" class="Steps-itemMediaImage" />
            </div>
            <div class="Steps-itemContent">
              <h2 class="Steps-itemHeading">Stakeholders See Progress</h2>
              <ul class="Steps-itemList">
                <li class="Steps-itemListItem">Reports are delivered directly to the stakeholders inbox or shared via a unique URL.</li>
                <li class="Steps-itemListItem">Stakeholders receive detailed information about features that shipped.</li>
              </ul>
            </div>
          </li>
        </ol>
      </div>
    </div>`
  }
}
